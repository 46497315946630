import React from 'react';
import { observer } from 'mobx-react';
import { computed } from 'mobx';
import { OnboardingChooseDeckWrapper } from '@seedlang/hoc';
import styled from '@emotion/styled';
import { flexCenterColumn } from '@seedlang/style_mixins';
import { Theme } from '@seedlang/constants';
import Text from 'components/text';
import LearningPreferenceButton from 'components/onboarding/learning_preference_button';
import { AppUI } from '@seedlang/state';
import { isPresent } from '@seedlang/utils';
import { DictionaryIcon, SpeakingIcon, TriviaCircleIcon, WordListIcon } from '@seedlang/icons';
import Spinner from 'components/spinner';
import OnboardingContent from 'components/onboarding/onboarding_content';
import OnboardingWrapper from 'components/onboarding/onboarding_wrapper';

@observer
class OnboardingChooseDeck extends React.Component {

  @computed get showWorksheets() {
    return window.location.href.split("/group/")[1] === 'f74a4102-d65b-448c-b261-60b6be2c7eca';
  }

  @computed get wordsNumber() {
    if (isPresent(AppUI.targetLanguage)) {
      return (Math.floor(AppUI.targetLanguage.vocabTrainerWordsCount/100)*100).toLocaleString('en');
    } else if (this.props.targetLanguageId === 'DE') {
      return '20,000';
    } else {
      return '3000';
    }
  }

  render() {
    return (
      <OnboardingWrapper>
        <OnboardingContent>
          {
            this.props.showSpinner &&
              <Spinner
                background="white"
              />
          }
          {
            !this.props.showSpinner &&
              <div>
                {
                  this.showWorksheets &&
                    <LearningPreferenceButton
                      isMobile={AppUI.layout.isMobile}
                      height="100px"
                      icon={<TriviaCircleIcon />}
                      onClick={() => AppUI.routeStore.routeToNamed('groups.show', {groupId: "f74a4102-d65b-448c-b261-60b6be2c7eca"})}
                      background={Theme.purple}
                      name="Find exercises from Easy German videos."
                    />
                }
                <LearningPreferenceButton
                  isMobile={AppUI.layout.isMobile}
                  height="100px"
                  icon={<SpeakingIcon />}
                  onClick={() => this.props.onSelectLearningPreference('content_tree')}
                  background={Theme.orange}
                  name="Give me speaking and listening practice."
                />
                <LearningPreferenceButton
                  isMobile={AppUI.layout.isMobile}
                  height="100px"
                  icon={<DictionaryIcon />}
                  onClick={() => this.props.onSelectLearningPreference('vocab')}
                  background={Theme.green}
                  name="Help me to increase my vocabulary."
                />
                {
                  AppUI.targetLanguageId === 'DE' && !this.showWorksheets &&
                    <LearningPreferenceButton
                      isMobile={AppUI.layout.isMobile}
                      height="100px"
                      icon={<TriviaCircleIcon />}
                      onClick={() => this.props.onSelectLearningPreference('trivia')}
                      background={Theme.purple}
                      name="I'd like to learn while playing trivia."
                    />
                }
                {
                  AppUI.targetLanguageId !== 'DE' && !this.showWorksheets &&
                    <LearningPreferenceButton
                      isMobile={AppUI.layout.isMobile}
                      height="100px"
                      icon={<WordListIcon />}
                      onClick={() => this.props.onSelectLearningPreference('conjugation')}
                      background={Theme.purple}
                      name="I'd like to learn the conjugation of verbs."
                    />
                }
              </div>
          }
        </OnboardingContent>
      </OnboardingWrapper>
    );
  }
}

export default OnboardingChooseDeckWrapper(OnboardingChooseDeck);
