import React from 'react';
import { observer } from 'mobx-react';
import styled from '@emotion/styled';
import { autorun, computed, observable } from 'mobx';
import DeleteButton from 'components/button/delete_button';
import { WordDerivationStore, SourceStore, SynonymAssociationStore, LevelStore, RouteStore, SentenceStore, SentenceSubmissionStore, WordConceptStore, WordSentenceStore, WordStore, WordStructureStore, WordTypeStore, LanguageStore} from '@seedlang/stores';
import { isBlank, isPresent } from '@seedlang/utils';
import { flatten, orderBy, uniq, uniqBy } from 'lodash';
import { Link } from 'react-router';
import InPlaceVideoUpload from 'components/form/in_place_video_upload';
import autobind from 'autobind-decorator';
import Paginator from 'components/paginator';
import CheckIfTrue from 'components/check_if_true';
import InPlaceSelect from 'components/form/in_place_select';
import InPlaceText from 'components/form/in_place_text';
import InPlaceCheckbox from 'components/form/in_place_checkbox';
import WordSourceEdit from 'pages/builder/sources/word_source_edit';
import GenderVariantEdit from "./gender_variant_edit";
import Modal from 'components/modal';
import SentenceSubmissionIndex from 'pages/builder/sentence_submissions/sentence_submission_index';
import ExcludeEdit from 'pages/builder/words/exclude_edit';
import { Constants } from '@seedlang/constants';
import Copy from 'components/copy';
import ReactTooltip from "react-tooltip";
import MinimalPairEdit from "./minimal_pair_edit";
import ConceptWordIndex from 'pages/builder/concept_words/concept_word_index';
import WordSearch from 'components/word_search';
import Text from "components/text";
import { Languages } from "@seedlang/constants/src";
import InfoTooltip from "components/info_tooltip";
import Alert from "components/alert";
import SquareButton from "components/button/square_button";
import Spinner from "components/spinner";
import SearchMultiSelect from "components/form/search_multi_select";
import RelatedWordTableRow from "pages/builder/words/related_word_table_row";

const ReviewedUser = styled.div`
  font-size: 12px;
  padding-left: 18px;
  background: #FFF;
`;

const ConjugatedVerbStringGrouping = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

@observer
class WordEdit extends React.Component {
  @observable showNote = false;
  @observable editableConjugations = false;
  @observable showConceptModal = false;
  @observable unknownComponents = [];
  @observable showFormToCreateNewWordStructure = false;
  @observable showEditSynonyms = false;
  @observable showEditRelatedWords = false;
  @observable mergeSpinner = false;
  @observable mergeMessage;
  @observable showWorksheets = false;

  @computed get noun() {
    return WordStore.showData.hasWordType && WordStore.showData.wordType.abbreviation === 'noun';
  }

  @computed get pronoun() {
    return WordStore.showData.hasWordType && WordStore.showData.wordType.abbreviation === 'pron';
  }

  @computed get verb() {
    return WordStore.showData.hasWordType && WordStore.showData.wordType.abbreviation === 'vrb';
  }

  @computed get adverb() {
    return WordStore.showData.hasWordType && WordStore.showData.wordType.abbreviation === 'adv';
  }

  @computed get adj() {
    return WordStore.showData.hasWordType && WordStore.showData.wordType.abbreviation === 'adj';
  }

  @computed get number() {
    return WordStore.showData.hasWordType && WordStore.showData.wordType.abbreviation === 'num';
  }

  constructor(props) {
    super(props);
    WordStore.getShow({ queryStrings: {expand_translation_sources: true, expand_worksheets: true}, ids: {wordId: this.props.params.wordId }}, this.afterWordGetShow);
    WordSentenceStore.getIndex({sort: 'level_abbreviation, target_text', queryStrings: {expanded: true, back_end: true}, ids: {wordId: this.props.params.wordId }});
    WordConceptStore.getIndex({ids: {wordId: this.props.params.wordId}});
    if (!LevelStore.hasIndexData) {
      LevelStore.getIndex();
    }
  }

  afterWordGetShow(resp) {
    WordStore.setShowData(resp);
    if (resp.root && resp.wordTypeId === "4c4389c1-f805-4d1c-ba45-6721cf400aef") {
      LanguageStore.getShow({ids: {languageId: resp.languageId}});
    }
  }

  componentWillUnmount() {
    WordStore.clearShowData();
  }

  componentDidUpdate(prevProps) {
    if (this.props.params.wordId !== prevProps.params.wordId) {
      SentenceSubmissionStore.getIndex({ids: {wordId: this.props.params.wordId }});
      WordStore.getShow({ ids: {wordId: this.props.params.wordId }});
      WordSentenceStore.getIndex({ids: {wordId: this.props.params.wordId }});
      WordConceptStore.getIndex({ids: {wordId: this.props.params.wordId}});
    }
  }

  @autobind afterChange() {
    WordStore.reloadShow();
  }

  @autobind onChangeConcepts() {
    this.afterChange();
    WordConceptStore.getIndex({ids: {wordId: this.props.params.wordId}});
  }

  @autobind clearRoot() {
    WordStore.update({ data: {root_word_id: null}, ids: {wordId: this.props.params.wordId }}, this.afterChange);
  }

  @autobind togglePluralNoun(wordId) {
    if (WordStore.showData?.pluralNouns.map(item => item.id).indexOf(wordId) !== -1) {
      WordStore.update({ data: {singular_noun_id: null}, ids: {wordId: wordId }}, this.afterChange);
    } else {
      WordStore.update({ data: {singular_noun_id: this.props.params.wordId}, ids: {wordId: wordId }}, this.afterChange);
    }
  }

  @autobind createFeminineForm(targetText) {
    const feminineForm = WordStore.showData.childWords.filter((word) => word.targetText === targetText)[0]
    if (feminineForm) {
      WordStore.update({ids: {wordId: WordStore.showData.id}, data: {gender_variant_id: feminineForm.id}}, this.afterChange);
    } else {
      WordStore.create({data: {
          root: false,
          target_text: targetText,
          root_word_id: WordStore.showData.id,
          language_id: WordStore.showData.languageId,
          root_word_type_id: WordStore.showData.rootWordType?.id,
          word_type_id: WordStore.showData.wordType.id,
        }}, this.afterCreateFeminineForm)
    }
  }

  @autobind afterCreateFeminineForm(resp) {
    WordStore.update({ids: {wordId: WordStore.showData.id}, data: {gender_variant_id: resp.word.id}}, this.afterChange);
  }

  @autobind onSelectPlural(word) {
    WordStore.update({ids: {wordId: word.id}, data: {root_word_id: WordStore.showData.id}}, this.afterChange)
  }

  @autobind onCreatePlural(targetText) {
    WordStore.createPlural({ids: {wordId: WordStore.showData.id}, data: {target_text: targetText}}, this.afterCreatePlural);
  }

  @autobind afterCreatePlural(resp) {
    RouteStore.routeToNamed('builder.words.edit', {wordId: resp.word.id});
  }

  @autobind onMergeDuplicateWords() {
    this.mergeSpinner = true;
    WordStore.mergeDuplicateWords({ ids: {wordId: WordStore.showData.id}}, this.afterMergeDuplicateWords);
  }

  @autobind afterMergeDuplicateWords(resp) { // link to merged word
    this.mergeSpinner = false;
    if (resp.didMerge) {
      if (resp.wordId !== WordStore.showData.id) {
        this.mergeMessage = "The current word was merged and destroyed. Rerouting you to merged word...";
        RouteStore.routeToNamed('builder.words.edit', {wordId: resp.wordId});
      } else {
        this.mergeMessage = "Merge was successful. Duplicates were destroyed.";
      }
    } else {
      this.mergeMessage = "No merging was performed"
    }
  }

  @autobind createNewWordStructure() {
    if (isPresent(this.refs.text.value)) {
      WordStructureStore.create({
          data: {
            text: this.refs.text.value,
            sourceText: this.refs.sourceText.value,
            wordId: WordStore.showData?.id,
          }}
        , this.afterUpdateNewWordStructure);
    }  }

  @autobind afterUpdateNewWordStructure(resp) {
    if (resp.success) {
      this.showFormToCreateNewWordStructure = false;
    }
    this.afterChange();
    this.unknownComponents = resp.unknownComponents;
  }

  @autobind deleteWordStructure(wordStructureId) {
    WordStructureStore.destroy({ids: {wordStructureId: wordStructureId}}, this.afterChange);
  }

  @autobind deleteSynonymAssociation(synonymAssociationId) {
    SynonymAssociationStore.destroy({ids: {synonymAssociationId: synonymAssociationId}}, this.afterChange);
  }

  @autobind onDestroyWordDerivation(params={}) {
    WordDerivationStore.destroy({ids: {stemId: params['stemId'], childId: params['childId']}}, this.afterChange);
  }

  @autobind toggleShowAddSynonyms() {
    this.showEditSynonyms = !this.showEditSynonyms;
    const sourceIds = WordStore.showData.translationSources.filter(item => item.source && item.source?.languageId == 'EN' && !item.rejected && (!item.userId || item.accepted)).map(item => item.source?.id);
    if (isPresent(sourceIds)) {
      SourceStore.getIndex({filters: {id: sourceIds}});
    }
  }

  @computed get potentialSynonyms() {
    return uniqBy(flatten(SourceStore.indexData?.map(source => source.translations.filter(item => item.languageId === WordStore.showData.languageId && item.id !== WordStore.showData.id && item.root))), item => item.id);
  }

  @autobind synonymAssociation(wordId) {
    return WordStore.showData.synonymAssociations.find(item => item.synonym?.id === wordId);
  }

  @autobind onToggleSynonym(wordId) {
    if (isPresent(this.synonymAssociation(wordId))) {
      SynonymAssociationStore.destroy({ids: {synonymAssociationId: this.synonymAssociation(wordId).id}}, this.afterChange);
    } else {
      SynonymAssociationStore.create({data: {wordId: WordStore.showData.id, synonymId: wordId}}, this.afterChange);
    }
  }

  @computed get wordDerivationStemsOptions() {
    return WordStore.hasShowData && isPresent(WordStore.showData.wordDerivationStems)
      ? WordStore.showData.wordDerivationStems.map(item => this.wordToMultiSearchOption(item))
      : [];
  }

  @computed get wordDerivationChildrenOptions() {
    return WordStore.hasShowData && isPresent(WordStore.showData.wordDerivationChildren)
      ? WordStore.showData.wordDerivationChildren.map(item => this.wordToMultiSearchOption(item))
      : [];
  }

  @autobind wordToMultiSearchOption(word) {
    return {
      id: word.id,
      name: word && word.targetTextWithDefiniteArticle,
      targetWithWordTypeParentInfinitiveAndSource: word && word.targetWithWordTypeParentInfinitiveAndSource,
    }
  }

  @autobind onCreateWordDerivation(params: {}) {
    WordDerivationStore.create({data: {stemId: params['stemId'], childId: params['childId']}}, this.afterChange);
  }

  @autobind clickShowEditRelatedWords() {
    this.showEditRelatedWords = true;
  }

  @computed get levelOptions() {
    if (!LevelStore.hasIndexData || !WordStore.hasShowData) { return }
    const options = LevelStore.indexData.filter(level => isPresent(level.abbreviation) && level.abbreviation !== 'N').map(level => [level.id, level.abbreviation]);
    if (WordStore.showData.wordType?.name === 'Number') {
      return options.slice(0,2);
    } return options;
  }

  @computed get worksheets() {
    if (this.showWorksheets) {
      return WordStore.showData.worksheets;
    } return WordStore.showData.worksheets.slice(0,1);
  }


  @autobind onDelete() {
    WordStore.destroy({ ids: { wordId: WordStore.showData.id } }, () => RouteStore.routeToNamed('builder.words.index', {page: 1}));
  }

  _content() {
    if (WordStore.hasShowData) {
      return (
        <div className="word-edit">
          {
            this.showNote &&
              <Modal
                onCloseModal={() => this.showNote = false}
              >
                <fieldset>
                  <legend>Note</legend>
                  <InPlaceText
                    defaultValue={WordStore.showData.note}
                    model="words"
                    inputType="textarea"
                    field="note"
                    id={WordStore.showData.id}
                    afterChange={this.afterChange}
                  />
                </fieldset>
              </Modal>
          }
          {
            this.showEditSynonyms &&
              <Modal
                onCloseModal={() => this.showEditSynonyms = false}
                gradient
                title='Manage synonyms'
              >
                <Text margin='0 0 10px'>Synonyms can only be selected among other words that have at least one source in common among <i>{WordStore.showData.sourceList}</i>.</Text>
                {
                  !SourceStore.hasIndexData &&
                  <Spinner />
                }
                {
                  SourceStore.hasIndexData && isPresent(this.potentialSynonyms) &&
                  <table className="table-wrapper">
                    <tbody>
                      {
                        this.potentialSynonyms.map(item => {
                          return (
                            <tr key={item.id}>
                              <td>{item.targetTextWithGenderAndWordType}</td>
                              <td>
                                <InPlaceCheckbox
                                  value={isPresent(this.synonymAssociation(item.id))}
                                  onChange={() => this.onToggleSynonym(item.id)}
                                />
                              </td>
                            </tr>
                          )
                        })
                      }
                    </tbody>
                  </table>
                }
                {
                  SourceStore.hasIndexData && isBlank(this.potentialSynonyms) &&
                  <div>There are no other words with the sources <i>{WordStore.showData.sourceList}</i>.</div>
                }
              </Modal>
          }
          {
            this.showEditRelatedWords &&
              <Modal
                onCloseModal={() => this.showEditRelatedWords = false}
                gradient
                title='Manage Related Words'
              >
                <div style={{display: 'flex', flexDirection: 'column', height: '600px'}}>
                  <Text heading={3}>Stems</Text>
                  <Text small>Words from which <i>{WordStore.showData.targetText}</i> was build.</Text>
                  <SearchMultiSelect
                    options={this.wordDerivationStemsOptions}
                    excludeIds={[WordStore.showData.id]}
                    onCreate={(word) => this.onCreateWordDerivation({childId: WordStore.showData.id, stemId: word.id})}
                    onDestroy={(stemId) => this.onDestroyWordDerivation({childId: WordStore.showData.id, stemId: stemId})}
                    searchStore={WordStore}
                    searchFilters={[{key: 'root', value: true}, {key: 'language_id', value: WordStore.showData.languageId}]}
                    searchField="target_text"
                    displayField="targetWithWordTypeParentInfinitiveAndSource"
                    sort="target_text"
                    linkTo="builder.words.edit"
                    linkId="wordId"
                  />
                  <Text heading={3} margin='20px 0 0 0'>Children</Text>
                  <Text small>Words which "contain" or were build with <i>{WordStore.showData.targetText}</i> as a stem.</Text>
                  <SearchMultiSelect
                    options={this.wordDerivationChildrenOptions}
                    excludeIds={[WordStore.showData.id]}
                    onCreate={(word) => this.onCreateWordDerivation({stemId: WordStore.showData.id, childId: word.id})}
                    onDestroy={(childId) => this.onDestroyWordDerivation({stemId: WordStore.showData.id, childId: childId})}
                    searchStore={WordStore}
                    searchFilters={[{key: 'root', value: true}, {key: 'language_id', value: WordStore.showData.languageId}]}
                    searchField="target_text"
                    displayField="targetWithWordTypeParentInfinitiveAndSource"
                    sort="target_text"
                    linkTo="builder.words.edit"
                    linkId="wordId"
                    keepInputOnSelect
                  />
                </div>
              </Modal>
          }
          {
            this.showConceptModal &&
              <ConceptWordIndex
                hideWordCard
                onCloseModal={() => this.showConceptModal = false}
                word={WordStore.showData}
                onChange={this.onChangeConcepts}
              />
          }
          <div className="row">
            <div className="col-xs-6">
              <fieldset>
                <legend>Video Clips</legend>
                {
                  WordStore.showData.target && WordStore.showData.target.videoClips.map(item => {
                    return (
                      <div
                        key={item.id}
                      >
                        <InPlaceVideoUpload
                          model="video_clips"
                          videoClip={item}
                          onChange={this.afterChange}
                          querystring={{
                            videoable_id: WordStore.showData.target && WordStore.showData.target.id,
                            videoable_type: "Target",
                          }}
                        />
                      </div>
                    )
                  })
                }
                {
                  WordStore.showData?.target?.id && isPresent(WordStore.showData?.target?.videoClips) &&
                    <div>
                      <InPlaceCheckbox
                        icon="check"
                        model="targets"
                        id={WordStore.showData.target.id}
                        value={WordStore.showData.target.replaceVideo}
                        field="replace_video"
                      >
                        Replace Video
                      </InPlaceCheckbox>
                    </div>
                }
                {
                  isBlank(WordStore.showData?.target?.videoClips) &&
                    <div>
                      <InPlaceVideoUpload
                        model="video_clips"
                        removeModel="target"
                        removeId={WordStore.showData.target.id}
                        videoClip={WordStore.showData.target.videoClip}
                        onChange={this.afterChange}
                        querystring={{
                          videoable_id: WordStore.showData.target.id,
                          videoable_type: "Target",
                        }}
                      />
                    </div>
                }
                <div>
                  <InPlaceCheckbox
                    icon="check"
                    model="words"
                    id={WordStore.showData.id}
                    value={WordStore.showData.needsSecondaryVideo}
                    field="needs_secondary_video"
                  >
                    Needs Secondary Video
                  </InPlaceCheckbox>
                </div>
              </fieldset>
            </div>
            <div className='col-xs-5'>
              <fieldset>
                <legend>Language</legend>
                {WordStore.hasShowData && Constants.LANGUAGE_OPTIONS[WordStore.showData.languageId]}
              </fieldset>
                <fieldset>
                <legend>Creation</legend>
                  {`${WordStore.hasShowData && WordStore.showData.createdAt && WordStore.showData.createdAt.formattedDateWithYear} by ${WordStore.hasShowData && isPresent(WordStore.showData.createdByUser?.name) ? WordStore.showData.createdByUser.name : 'unknown user'}`}
                </fieldset>
              <fieldset>
                <legend>Last Updated</legend>
                {WordStore.hasShowData && WordStore.showData.updatedAt && WordStore.showData.updatedAt.formattedDateWithYear}
              </fieldset>
              {
                this.verb &&
                  <fieldset style={{position: 'relative'}}>
                    <legend>Approved</legend>
                    <InPlaceCheckbox
                      model="words"
                      field="reviewed2"
                      value={WordStore.showData.reviewed2}
                      id={WordStore.showData.id}
                      icon="thumbs-up"
                      afterChange={this.afterChange}
                    >
                      Didactics
                    </InPlaceCheckbox>
                    {
                      isPresent(WordStore.showData.reviewed2User) &&
                        <ReviewedUser>
                          {WordStore.showData.reviewed2User?.name}
                        </ReviewedUser>
                    }
                  </fieldset>
              }
            </div>
          </div>
          <div className="row">
            <div className="col-xs-3">
              <fieldset>
                <legend>Target</legend>
                <div style={{display: 'flex'}}>
                  {
                    isPresent(WordStore.showData.target) && isPresent(WordStore.showData.target.id) &&
                      <Link
                        to={{name: 'builder.targets.edit', params: {targetId: WordStore.showData.target.id}}}
                      >
                        <i className="fa fa-chevron-right fa-block" />
                      </Link>
                  }
                  <InPlaceText
                    model="words"
                    field="target_text"
                    id={WordStore.showData.id}
                    defaultValue={WordStore.showData.targetText}
                    afterChange={this.afterChange}
                  />
                  {
                    WordStore.showData?.target?.hasGenders && WordStore.showData.target.gender &&
                      <span> ({WordStore.showData.target.gender})</span>
                  }
                </div>
                {
                  this.noun &&
                    <div style={{fontSize: "12px", marginTop: "5px"}}>With Article: {WordStore.showData.targetTextWithDefiniteArticle}</div>
                }
              </fieldset>
            </div>
            <div className="col-xs-3">
              <fieldset>
                <legend>Word Type</legend>
                {
                  WordTypeStore.hasIndexData && WordStore.hasShowData &&
                    <div>
                      <InPlaceSelect
                        clickToEdit={WordStore.showData.wordType && WordStore.showData.wordType.id}
                        options={WordTypeStore.allForSelect}
                        model="words"
                        field="word_type_id"
                        includeBlank
                        id={WordStore.showData.id}
                        value={WordStore.showData.wordType && WordStore.showData.wordType.id}
                        afterChange={this.afterChange}
                      />
                    </div>
                }
                {
                  WordStore.hasShowData && WordStore.showData.rootWordType && WordStore.showData.wordType && WordStore.showData.rootWordType.id !== WordStore.showData.wordType.id &&
                    <div style={{fontSize: 12, fontStyle: 'italic'}}>
                      Root: {WordStore.showData.rootWordType.name}
                    </div>
                }
                {
                  WordStore.hasShowData && isBlank(WordStore.showData.wordType) &&
                    <small style={{background: 'orange', color: 'white', fontSize: 12, fontStyle: 'italic'}}>Remember to use root form</small>
                }
              </fieldset>
            </div>
            <div className="col-xs-3">
              <fieldset>
                <legend>Source List</legend>
                <WordSourceEdit
                  word={WordStore.showData}
                  onChange={this.afterChange}
                  scopedToSentence={false}
                />
              </fieldset>
            </div>
            {
              (this.noun || this.pronoun) &&
                <div className='col-xs-2'>
                  <fieldset>
                    <legend>Gender</legend>
                    <InPlaceSelect
                      clickToEdit={WordStore.showData.gender}
                      options={[['masculine', 'masculine'], ['feminine', 'feminine'], ['neuter', 'neuter']]}
                      model="words"
                      field="gender"
                      includeBlank
                      id={WordStore.showData.id}
                      value={WordStore.showData.gender}
                      afterChange={this.afterChange}
                    />
                    {
                      !WordStore.showData.root &&
                        <div style={{marginTop: 5, fontSize: 12}}>
                          Root Word Gender: {WordStore.showData.rootWordGender}
                        </div>
                    }
                  </fieldset>
                </div>
            }
            <div className="col-xs-1">
              <fieldset>
                <legend>
                  Level
                  <span
                      data-for="level"
                      data-tip
                      style={{marginLeft: '5px'}}
                  >
                        <i className='fa fa-info-circle' />
                        <ReactTooltip
                            place="right"
                            type="dark"
                            effect="solid"
                            id="level"
                            className="custom-tooltip"
                        >
                          <div>Level can only be modified at the root,</div>
                          <div>and will update all child words' levels.</div>
                        </ReactTooltip>
                      </span>
                </legend>
                {
                  isPresent(this.levelOptions) &&
                    <InPlaceSelect
                      includeBlank
                      options={this.levelOptions}
                      model="words"
                      field="levelId"
                      afterChange={this.afterChange}
                      value={WordStore.showData.levelId}
                      id={WordStore.showData.id}
                      clickToEdit={true}
                      disabled={!WordStore.showData.root}
                    />
                }
              </fieldset>
            </div>
          </div>
          <div className='row'>
            <div className="col-xs-3">
              <fieldset>
                <legend>Attributes</legend>
                <CheckIfTrue
                  value={WordStore.showData.root}
                  label="Is Root [Disabled]"
                />
                {
                  WordStore.showData.root &&
                    <div>
                      <InPlaceCheckbox
                      icon="check"
                      model="words"
                      id={WordStore.showData.id}
                      value={WordStore.showData.allowDuplicateRoots}
                      field="allow_duplicate_roots"
                      >
                      Allow Duplicate Roots
                      </InPlaceCheckbox>
                  </div>
                }
                {
                  this.noun &&
                    <CheckIfTrue
                      value={WordStore.showData.plural}
                      label="Is Plural [Disabled]"
                    />
                }
                {
                  this.adverb &&
                    <div>
                      <InPlaceCheckbox
                       icon="check"
                       model="words"
                       id={WordStore.showData.id}
                       value={WordStore.showData.hasAdjectiveForm}
                       field="has_adjective_form"
                      >
                       Has Adjective Form
                      </InPlaceCheckbox>
                    </div>
                }
                {
                  this.noun && WordStore.showData.plural &&
                    <div>
                     <InPlaceCheckbox
                      icon="check"
                      model="words"
                      id={WordStore.showData.id}
                      value={WordStore.showData.excludeAsPluralForm}
                      field="exclude_as_plural_form"
                     >
                      <div>Exclude as Plural Form</div>
                      <small>(often -N declined plural forms)</small>
                     </InPlaceCheckbox>
                    </div>
                }
                {
                  this.noun && !WordStore.showData.plural &&
                    <div>
                      {
                        WordStore.showData.pluralNouns.length !== 0 &&
                          <InPlaceCheckbox
                            icon="check"
                            model="words"
                            id={WordStore.showData.id}
                            value={WordStore.showData.noPlural}
                            field="no_plural"
                            withConfirmation={true}
                            message={"Remove plural nouns?"}
                            confirmationMessageFloatsRight={true}
                            display="flex"
                            right="auto"
                            left="130px"
                            afterChange={this.afterChange}
                          >
                            Has No Plural
                          </InPlaceCheckbox>
                      }
                      {
                        WordStore.showData.pluralNouns.length === 0 &&
                          <InPlaceCheckbox
                            icon="check"
                            model="words"
                            id={WordStore.showData.id}
                            value={WordStore.showData.noPlural}
                            field="no_plural"
                          >
                            Has No Plural
                          </InPlaceCheckbox>
                      }

                    </div>
                }
                {
                  this.noun &&
                    <div>
                     <InPlaceCheckbox
                      icon="check"
                      model="words"
                      id={WordStore.showData.id}
                      value={WordStore.showData.useParenthesesOnGender}
                      field="use_parentheses_on_gender"
                      afterChange={this.afterChange}
                     >
                      Article Is Hidden (But Does Exist)
                     </InPlaceCheckbox>
                    </div>
                }
                {
                  this.noun &&
                    <div>
                      <InPlaceCheckbox
                        icon="check"
                        model="words"
                        id={WordStore.showData.id}
                        value={WordStore.showData.articleIsHidden}
                        field="article_is_hidden"
                        afterChange={this.afterChange}
                      >
                        Article Is Hidden (And Doesn't Exist)
                      </InPlaceCheckbox>
                    </div>
                }
                {
                  (this.noun || this.verb) && ['FR','CA'].indexOf(WordStore.showData.languageId) !== -1 &&
                    <div>
                     <InPlaceCheckbox
                      icon="check"
                      model="words"
                      id={WordStore.showData.id}
                      value={WordStore.showData.articleHasApostrophe}
                      field="article_has_apostrophe"
                      afterChange={this.afterChange}
                     >
                       {this.noun ? 'Article' : 'Pronoun'} Takes Apostrophe
                     </InPlaceCheckbox>
                    </div>
                }
                {
                    this.noun && (WordStore.showData.languageId === 'FR' || WordStore.showData.languageId === 'ES') &&
                      <div>
                        <InPlaceCheckbox
                            icon="check"
                            model="words"
                            id={WordStore.showData.id}
                            value={WordStore.showData.articleTakesOppositeGender}
                            field="article_takes_opposite_gender"
                            afterChange={this.afterChange}
                        >
                          Article Takes Opposite Gender
                        </InPlaceCheckbox>
                      </div>
                }
                {
                  this.verb &&
                    <div>
                      <InPlaceCheckbox
                        icon="check"
                        model="words"
                        id={WordStore.showData.id}
                        value={WordStore.showData.isPrefix}
                        field="is_prefix"
                      >
                        Is Prefix
                      </InPlaceCheckbox>
                    </div>
                }
                {
                  this.verb &&
                    <div>
                    <InPlaceCheckbox
                      icon="check"
                      model="words"
                      id={WordStore.showData.id}
                      value={WordStore.showData.isStem}
                      field="is_stem"
                    >
                      Is Stem
                    </InPlaceCheckbox>
                    </div>
                }
                {
                  this.number &&
                    <div>
                      <InPlaceCheckbox
                        icon="check"
                        model="words"
                        id={WordStore.showData.id}
                        value={WordStore.showData.isDate}
                        field="is_date"
                      >
                        Is Date
                      </InPlaceCheckbox>
                    </div>
                }
                {
                  WordStore.showData.root &&
                    <span
                      data-for="public"
                      data-tip
                      style={{ display: "flex", fontSize: "14px", alignItems: "center" }}
                    >
                      <CheckIfTrue
                        circle
                        value={WordStore.showData.publicInVocab}
                        width={"20px"}
                      />
                      Public in dictionary
                      {
                        !isBlank(WordStore.showData.messagePublicInVocab) &&
                          <ReactTooltip
                            place="right"
                            type="dark"
                            effect="solid"
                            id="public"
                            className="custom-tooltip"
                          >
                            {
                              WordStore.showData.messagePublicInVocab.map(item => {
                                return (
                                  <div>{item}</div>
                                );
                              })
                            }
                          </ReactTooltip>
                      }
                    </span>
                }
              </fieldset>
            </div>
            <div className="col-xs-3">
              <fieldset>
                <legend>Exclude From</legend>
                {
                  WordStore.hasShowData &&
                    <ExcludeEdit
                      word={WordStore.showData}
                      onChange={this.afterChange}
                    />
                }
              </fieldset>
            </div>
            <div className="col-xs-3">
              <fieldset style={{position: 'relative', display: 'flex'}}>
                <legend>
                  Minimal pair
                  <span
                    data-for="minimal-pair"
                    data-tip
                    style={{marginLeft: '5px'}}
                  >
                        <i className='fa fa-info-circle' />
                        <ReactTooltip
                          place="right"
                          type="dark"
                          effect="solid"
                          id="minimal-pair"
                          className="custom-tooltip"
                        >
                          <div>A word that differs only by one sound from this one.</div>
                          <div>Examples: <i>Ofen</i>/<i>offen</i>, <i>cul</i>/<i>cou</i>, <i>peso</i>/<i>piso</i>...</div>
                        </ReactTooltip>
                      </span>
                </legend>
                {
                  isPresent(WordStore.showData.genderVariant) &&
                  <Link
                    to={{
                      name: 'builder.words.edit',
                      params: {wordId: WordStore.showData.genderVariant.id}
                    }}
                    className="on-click"
                  >
                    <i className="fa fa-chevron-right fa-block"/>
                  </Link>
                }
                <MinimalPairEdit
                  word={WordStore.showData}
                  onChange={this.afterChange}
                />
              </fieldset>
            </div>

            <div className='col-xs-3'>
              <fieldset>
                <legend>Links</legend>
                {
                  WordStore.showData && WordStore.showData.root &&
                    <div>
                      <a
                        className='underline'
                        style={{fontSize: 14}}
                        href={`/vocab/words/${WordStore.showData.id}`}
                        target='_blank'
                        rel="noopener noreferrer"
                      >
                        Vocab Page
                      </a>
                    </div>
                }
                {
                  WordStore.showData && WordStore.showData.vocabTriadConceptId &&
                  <div>
                    <a
                      className='underline'
                      style={{fontSize: 14}}
                      href={`/builder/concepts/${WordStore.showData.vocabTriadConceptId}`}
                      target='_blank'
                      rel="noopener noreferrer"
                    >
                      Triad Concept
                    </a>
                  </div>
                }
                {
                  WordStore.showData && WordStore.showData.vocabTriadDeckId &&
                  <div>
                    <a
                      className='underline'
                      style={{fontSize: 14}}
                      href={`/builder/decks/${WordStore.showData.vocabTriadDeckId}`}
                      target='_blank'
                      rel="noopener noreferrer"
                    >
                      Triad Deck
                    </a>
                  </div>
                }
                {
                  WordStore.showData.forumThreadId &&
                    <div>
                      <a
                        className='underline'
                        style={{fontSize: 14}}
                        href={`https://www.seedlang.com/discussions/${Constants.VOCAB_FORUM_IDS[SentenceStore.showData.languageId]}/threads/${WordStore.showData.forumThreadId}`}
                        target='_blank'
                        rel="noopener noreferrer"
                      >
                        Forum
                      </a>
                    </div>
                }
              </fieldset>
            </div>
            {
              false && WordStore.showData.root &&
                <div className='col-xs-3'>
                  <fieldset>
                    <legend>Register</legend>
                      <InPlaceSelect
                        clickToEdit={WordStore.showData.register}
                        options={Constants.REGISTER_OPTIONS}
                        model="words"
                        field="register"
                        includeBlank
                        id={WordStore.showData.id}
                        value={WordStore.showData.register}
                        afterChange={this.afterChange}
                      />
                  </fieldset>
                </div>
            }
          </div>
          {
            isPresent(WordStore.showData.stems) && (
              <div className="row">
                <div className="col-xs-12">
                  <fieldset>
                    <legend>Stems</legend>
                    <table className="table-wrapper">
                      <thead>
                        <tr>
                          <th width="20"></th>
                          <th>Target</th>
                          <th>Sentences</th>
                        </tr>
                      </thead>
                      <tbody>
                        {
                          WordStore.showData.stems.map((item) => {
                            return (
                              <tr key={item.id}>
                                <td>
                                  <Link
                                    to={{ name: 'builder.words.edit', params: { wordId: item.id } }}
                                    className="on-click"
                                  >
                                    <i className="fa fa-chevron-right fa-block" />
                                  </Link>
                                </td>
                                <td>
                                  <div className="text">
                                    { item.targetText }
                                  </div>
                                </td>
                                <td>
                                  <div className="text">
                                    { item.sentencesCount }
                                  </div>
                                </td>
                              </tr>
                            );
                          })
                        }
                      </tbody>
                    </table>
                  </fieldset>
                </div>
              </div>
            )
          }
          {
            WordStore.showData.hasPrefix && (
              <div className="row">
                <div className="col-xs-12">
                  <fieldset>
                    <legend>Prefix</legend>
                    <table className="table-wrapper">
                      <thead>
                        <tr>
                          <th width="20"></th>
                          <th>Target</th>
                          <th>Sentences</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            <Link
                              to={{ name: 'builder.words.edit', params: { wordId: WordStore.showData.prefix.id } }}
                              className="on-click"
                            >
                              <i className="fa fa-chevron-right fa-block" />
                            </Link>
                          </td>
                          <td>
                            <div className="text">
                              { WordStore.showData.prefix.targetText }
                            </div>
                          </td>
                          <td>
                            <div className="text">
                              { WordStore.showData.prefix.sentencesCount }
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </fieldset>
                </div>
              </div>
            )
          }
          {
            WordStore.showData.root && this.noun && WordStore.showData.plural &&
             <div className="row">
               <div className="col-xs-12">
                  <fieldset>
                    <legend>Singular Noun</legend>
                  </fieldset>
                </div>
              </div>
          }
          {
            WordStore.showData.root && this.noun && !WordStore.showData.plural &&
             <div className="row">
               <div className={WordStore.showData.languageId === 'DE' ? "col-xs-6" : "col-xs-9"}>
                  <fieldset>
                    <legend>Plural Nouns</legend>
                    <table className="table-wrapper">
                      <thead>
                        <tr>
                          <th width="20"></th>
                          <th>Target</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        {
                          WordStore.showData.pluralNouns.map((item) => {
                            return (
                              <tr key={item.id}>
                                <td>
                                  <Link
                                    to={{ name: 'builder.words.edit', params: { wordId: item.id } }}
                                    className="on-click"
                                  >
                                    <i className="fa fa-chevron-right fa-block" />
                                  </Link>
                                </td>
                                <td>
                                  <div className="text">
                                    <Link
                                      to={{name: "builder.words.edit", params: {wordId: item.id}}}
                                      className='underline'
                                    >
                                      {item.targetTextWithDefiniteArticle}
                                      {item.plural && <span> (plur)</span>}
                                      {item.isNoun && !item.plural && <span> (sing)</span>}
                                    </Link>
                                  </div>
                                </td>
                                <td>
                                  <DeleteButton
                                    onConfirm={() => this.togglePluralNoun(item.id)}
                                    message="Remove from plural nouns?"
                                    className="fa-block right"
                                  />
                                </td>
                              </tr>
                            )
                          })
                        }
                      </tbody>
                    </table>
                    <div
                      style={{marginTop: "5px"}}
                    >
                      <WordSearch
                        onSubmit={this.onSelectPlural} // TODO
                        displayField="targetWithWordTypeParentAndSource"
                        placeholder="Find or create another plural noun"
                        filters={{root: false, root_word_type_id: WordStore.showData.wordType.id, plural: true, language_id: WordStore.showData.languageId}}
                        onCreateValue={this.onCreatePlural} // TODO
                        height="32px"
                        width="100%"
                      />
                    </div>
                  </fieldset>
                </div>
                {
                  WordStore.showData.languageId === 'DE' &&
                    <div className="col-xs-6">
                      <fieldset>
                        <legend>False Plurals</legend>
                        <InPlaceText
                          model="words"
                          field="false_plurals"
                          id={WordStore.showData.id}
                          defaultValue={WordStore.showData.falsePlurals}
                          afterChange={this.afterChange}
                        />
                        <small>Used in Plural Trainer. Separate with comma and no space. Ex: Fernsehsender,Fernsehsendere</small>
                      </fieldset>
                    </div>
                }
              </div>
          }
          {
            !WordStore.showData.root && isPresent(WordStore.showData.rootWord) && isPresent(WordStore.showData.rootWord.id) && (
              <div className="row">
                <div className="col-xs-12">
                  <fieldset>
                    <legend>Root Word</legend>
                    <table className="table-wrapper">
                      <thead>
                        <tr>
                          <th width="20"></th>
                          <th>Target</th>
                          <th>Sentences</th>
                        </tr>
                      </thead>
                      <tbody>
                       <tr>
                         <td>
                           <Link
                             to={{ name: 'builder.words.edit', params: { wordId: WordStore.showData.rootWord.id } }}
                             className="on-click"
                           >
                             <i className="fa fa-chevron-right fa-block" />
                           </Link>
                         </td>
                         <td>
                           <div className="text">
                             { WordStore.showData.rootWord.targetText }
                           </div>
                         </td>
                         <td>
                           <div className="text">
                             { WordStore.showData.rootWord.sentencesCount }
                           </div>
                         </td>
                       </tr>
                      </tbody>
                    </table>
                    <div
                      style={{marginTop: 5, fontSize: 14, textDecoration: 'underline', cursor: 'pointer'}}
                      onClick={this.clearRoot}
                    >
                      Clear Root
                    </div>
                  </fieldset>
                </div>
              </div>
            )
          }
          {
            WordStore.showData.root && (this.adj || this.number) && WordStore.showData.languageId !== 'DE' &&
              <div className="row">
                <div className="col-xs-12">
                  <fieldset style={{position: 'relative', display: 'flex'}}>
                    <legend>
                      Feminine form
                      <span
                        data-for="feminine-form"
                        data-tip
                        style={{marginLeft: '5px'}}
                      >
                        <i className='fa fa-info-circle' />
                        <ReactTooltip
                          place="right"
                          type="dark"
                          effect="solid"
                          id="feminine-form"
                          className="custom-tooltip"
                        >
                          <div>The second form heard in the video. It will be displayed in the dictionary and on cards.</div>
                          <div>It has to be a child word of <i>{WordStore.showData.targetText}</i>.</div>
                          <div>If it doesn't exist, a new child word will be created.</div>
                        </ReactTooltip>
                      </span>
                    </legend>
                    {
                      isPresent(WordStore.showData.genderVariant) &&
                      <Link
                        to={{
                          name: 'builder.words.edit',
                          params: {wordId: WordStore.showData.genderVariant.id}
                        }}
                        className="on-click"
                      >
                        <i className="fa fa-chevron-right fa-block"/>
                      </Link>
                    }
                    <GenderVariantEdit
                      word={WordStore.showData}
                      placeholder="Enter feminine form"
                      displayField="targetWithGenderAndSentencesCount"
                      onChange={this.afterChange}
                    />
                  </fieldset>
                </div>
              </div>
          }
          {
            isPresent(WordStore.showData.childWords) && (
              <div className="row">
                <div className="col-xs-12">
                  <fieldset>
                    <legend>Child Words</legend>
                    <table className="table-wrapper">
                      <thead>
                        <tr>
                          <th width="20"></th>
                          <th>Target</th>
                          <th>Sources</th>
                          <th>Sentences</th>
                          <th>Word type</th>
                          <th>Concepts</th>
                          {
                            this.noun &&
                              <th>Plural</th>
                          }
                        </tr>
                      </thead>
                      <tbody>
                        {
                          WordStore.showData.childWords.filter(item => !item.simple).map((item) => {
                            return (
                              <tr key={item.id}>
                                <td>
                                  <Link
                                    to={{ name: 'builder.words.edit', params: { wordId: item.id } }}
                                    className="on-click"
                                  >
                                    <i className="fa fa-chevron-right fa-block" />
                                  </Link>
                                </td>
                                <td>
                                  <div className="text">
                                    <Link
                                      to={{name: "builder.words.edit", params: {wordId: item.id}}}
                                      className='underline'
                                    >
                                      {item.targetTextWithDefiniteArticle}
                                      {item.plural && <span> (plur)</span>}
                                      {item.isNoun && !item.plural && <span> (sing)</span>}
                                    </Link>
                                  </div>
                                </td>
                                <td>
                                  <div className="text">
                                    <i>{item.sourceList}</i>
                                  </div>
                                </td>
                                <td>
                                  <div className="text">
                                    { item.sentencesCount }
                                  </div>
                                </td>
                                <td>
                                  <div className="text">
                                    { item.wordTypeName }
                                  </div>
                                </td>
                                <td>
                                  <div className="text">
                                    {
                                      orderBy(item.concepts, ['name']).map(a => a.name).join(" | ")
                                    }
                                  </div>
                                </td>
                                {
                                  this.noun &&
                                    <td>
                                      <InPlaceCheckbox
                                        value={WordStore.showData?.pluralNouns.map(item => item.id).indexOf(item.id) !== -1}
                                        onChange={() => this.togglePluralNoun(item.id)}
                                        afterChange={this.afterChange}
                                        id={item.id}
                                        wrapperMargin="3px 3px 0 0"
                                        model="words"
                                        icon="check"
                                      />
                                    </td>
                                }

                              </tr>
                            );
                          })
                        }
                      </tbody>
                    </table>
                  </fieldset>
                </div>
              </div>
            )
          }
          {
            WordStore.showData.root && WordStore.showData.wordType?.showInVocabTrainer && (
              <div className="row">
                <div className="col-xs-12">
                  <fieldset>
                    <legend>Synonyms</legend>
                    <table className="table-wrapper">
                      <thead>
                        <tr>
                          <th width="20"></th>
                          <th>Target</th>
                          <th>Sources</th>
                          <th>Similarity</th>
                          <th>Sentences</th>
                          <th>Word type</th>
                          <th>Public</th>
                          <th width="20"></th>
                        </tr>
                      </thead>
                      <tbody>
                        {
                          WordStore.showData.synonymAssociations.filter(item => !item.simple).map((item) => {
                            return (
                              <tr key={item.id}>
                                <td>
                                  <Link
                                    to={{ name: 'builder.words.edit', params: { wordId: item.synonym.id } }}
                                    className="on-click"
                                  >
                                    <i className="fa fa-chevron-right fa-block" />
                                  </Link>
                                </td>
                                <td>
                                  <div className="text">
                                    <Link
                                      to={{name: "builder.words.edit", params: {wordId: item.synonym.id}}}
                                      className='underline'
                                    >
                                      {item.synonym.targetTextWithDefiniteArticle}
                                      {item.plural && <span> (plur)</span>}
                                      {item.isNoun && !item.plural && <span> (sing)</span>}
                                    </Link>
                                  </div>
                                </td>
                                <td>
                                  <div className="text">
                                    <i>{item.synonym.sourceList}</i>
                                  </div>
                                </td>
                                <td>
                                  <div className="text">
                                    { item.similarity.toLocaleString('en', {style: 'percent', maximumFractionDigits: 2}) }
                                  </div>
                                </td>
                                <td>
                                  <div className="text">
                                    { item.synonym.sentencesCount }
                                  </div>
                                </td>
                                <td>
                                  <div className="text">
                                    { item.synonym.wordTypeName }
                                  </div>
                                </td>
                                <td>
                                  <CheckIfTrue
                                    circle
                                    value={item.synonym.publicInVocab}
                                    width={"20px"}
                                  />
                                </td>
                                <td>
                                  <DeleteButton
                                    onConfirm={() => this.deleteSynonymAssociation(item.id)}
                                    message={"Remove this synonym association?"}
                                    className="fa-block right"
                                  />
                                </td>
                              </tr>
                            );
                          })
                        }
                        <tr
                          style={{cursor: 'pointer'}}
                        >
                          <td
                            colSpan="5"
                            onClick={this.toggleShowAddSynonyms}
                          >
                            <i className='fa fa-plus' style={{margin: '3px'}}/>
                            <Text fontSize="14px">Add Synonyms</Text>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </fieldset>
                </div>
              </div>
            )
          }
          {
            WordStore.showData.root && (
              <div className="row">
                <div className="col-xs-12">
                  <fieldset>
                    <legend>Related Words</legend>
                    <table className="table-wrapper">
                      <thead>
                        <tr>
                          <th width="20"></th>
                          <th>Target</th>
                          <th>Sources</th>
                          <th>Relationship</th>
                          <th>Sentences</th>
                          <th>Word type</th>
                          <th>Public</th>
                          <th width="20"></th>
                        </tr>
                      </thead>
                      <tbody>
                        {
                          WordStore.showData.wordDerivationStems.filter(item => !item.simple).map((item) => {
                            return (
                              <RelatedWordTableRow
                                relatedWord={item}
                                referenceWordId={WordStore.showData.id}
                                isStem
                                onDelete={this.onDestroyWordDerivation}
                              />
                            );
                          })
                        }
                        {
                          WordStore.showData.wordDerivationChildren.filter(item => !item.simple).map((item) => {
                            return (
                              <RelatedWordTableRow
                                relatedWord={item}
                                referenceWordId={WordStore.showData.id}
                                isStem={false}
                                onDelete={this.onDestroyWordDerivation}
                              />
                            );
                            }
                          )
                        }
                        <tr
                          style={{cursor: 'pointer'}}
                        >
                          <td
                            colSpan="5"
                            onClick={this.clickShowEditRelatedWords}
                          >
                            <i className='fa fa-plus' style={{margin: '3px'}}/>
                            <Text fontSize="14px">Edit Related Words</Text>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </fieldset>
                </div>
              </div>
            )
          }
          {
            WordStore.showData.root && this.noun && !WordStore.showData.plural &&
              <div className="row">
                <div className="col-xs-12">
                  <fieldset style={{position: 'relative', display: 'flex'}}>
                    <legend>
                      Gender Variant
                      <span
                        data-for="gender-variant"
                        data-tip
                        style={{marginLeft: '5px'}}
                      >
                        <i className='fa fa-info-circle' />
                        <ReactTooltip
                          place="right"
                          type="dark"
                          effect="solid"
                          id="gender-variant"
                          className="custom-tooltip"
                        >
                          <div>A root noun that is the equivalent to this one in the other gender.</div>
                          <div>Example <i>directrice</i> for <i>directeur</i>/<i>directora</i> for <i>director</i>.</div>
                          <div>It will appear on the dictionary word page as a link.</div>
                        </ReactTooltip>
                      </span>
                    </legend>
                    {
                      isPresent(WordStore.showData.genderVariant) &&
                      <Link
                        to={{
                          name: 'builder.words.edit',
                          params: {wordId: WordStore.showData.genderVariant.id}
                        }}
                        className="on-click"
                      >
                        <i className="fa fa-chevron-right fa-block"/>
                      </Link>
                    }
                    <GenderVariantEdit
                      word={WordStore.showData}
                      onChange={this.afterChange}
                    />
                  </fieldset>
                </div>
              </div>
          }
          {
            WordStore.showData.isVerb && WordStore.showData.root &&
              <div className="row">
                <div className="col-xs-12">
                  <fieldset>
                    <legend>
                      Verb structures
                      <i className='fa fa-warning' />
                      <InfoTooltip>
                        <div>These are not displayed on Seedlang currently</div>
                      </InfoTooltip>
                    </legend>
                    <table className="table-wrapper">
                      <thead>
                      <tr>
                        <th>{`${Languages[WordStore.showData.languageId]} verb structure`}</th>
                        <th>English translation</th>
                        <th width="30"/>
                      </tr>
                      </thead>
                      <tbody>
                      {
                        WordStore.showData.wordStructures?.map(wordStructure => {
                          return (
                            <tr
                              key={wordStructure.id}
                            >
                              <td>
                                <InPlaceText
                                  show={isBlank(wordStructure.text)}
                                  submitOnEnter
                                  model="word_structures"
                                  field="text"
                                  defaultValue={wordStructure.text}
                                  id={wordStructure.id}
                                  type="text"
                                  placeholder={`enter a ${Languages[WordStore.showData.languageId]} verb structure`}
                                  style={{margin: '3px'}}
                                  updateCallback={this.afterUpdateNewWordStructure}
                                />
                              </td>
                              <td>
                                <InPlaceText
                                  show={isBlank(wordStructure.sourceText)}
                                  submitOnEnter
                                  model="word_structures"
                                  field="source_text"
                                  defaultValue={wordStructure.sourceText}
                                  id={wordStructure.id}
                                  type="text"
                                  placeholder="enter the English translation"
                                  style={{margin: '3px'}}
                                  updateCallback={this.afterUpdateNewWordStructure}
                                />
                              </td>
                              <td>
                                <DeleteButton
                                  onConfirm={() => this.deleteWordStructure(wordStructure.id)}
                                  message={"Delete this word structure?"}
                                  className="fa-block right"
                                />
                              </td>
                            </tr>
                          );
                        })
                      }
                      {
                        this.showFormToCreateNewWordStructure &&
                          <tr>
                            <td>
                              <input
                                placeholder={`enter a ${Languages[WordStore.showData?.languageId]} verb structure`}
                                ref="text"
                              />
                            </td>
                            <td>
                              <input
                                placeholder="enter the English translation"
                                ref="sourceText"
                              />
                            </td>
                            <td>
                              <SquareButton
                                margin="0"
                                onClick={this.createNewWordStructure}
                              >
                                <i className='fa fa-plus' />
                              </SquareButton>
                            </td>
                          </tr>
                      }
                      {
                        !this.showFormToCreateNewWordStructure &&
                          <tr
                            style={{cursor: 'pointer'}}
                          >
                            <td
                              colSpan="4"
                              onClick={() => this.showFormToCreateNewWordStructure = true}
                            >
                              <i className='fa fa-plus' style={{margin: '3px'}}/>
                              <Text fontSize="14px">Create a new verb structure</Text>
                            </td>
                          </tr>
                      }
                      </tbody>
                    </table>
                    {
                      this.unknownComponents?.length > 0 &&
                        <Alert
                          width="100%"
                          margin="5px 0 0 0"
                          showCloseButton
                          onClose={() => this.unknownComponents = []}
                        >
                          <span>Word structure invalid. </span>
                          <span style={{fontStyle: 'italic'}}>{this.unknownComponents.join(', ')}</span>
                          <span>{` ${this.unknownComponents.length > 1 ? 'are' : 'is'} not in the ${Languages[WordStore.showData.languageId]} word structure components. `}</span>
                            <Link
                              className="underline"
                              to={{name: "builder.languages.edit", params: {languageId: WordStore.showData.languageId}}}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              {`Edit this list`}
                            </Link>
                        </Alert>
                    }
                    {
                      WordStore.showData.wordStructures?.length > 0 &&
                        <div>
                          <Text fontSize="13px">{`Word structure components for ${Languages[WordStore.showData?.languageId]}: `}</Text>
                          <Text fontSize="13px"><code>{LanguageStore.showData.wordStructureComponents}</code></Text>
                        </div>
                    }
                  </fieldset>
                </div>
              </div>
          }
          {
            WordStore.showData.isVerb &&
                <div className="row">
                  <div className="col-xs-12">
                    <fieldset>
                      <legend>Conjugated Verbs Table</legend>
                      {
                        isPresent(WordStore.showData.conjugatedVerbStrings) &&
                          <table className="table-wrapper">
                            <thead>
                              <tr>
                                <th />
                                <th>Concept</th>
                                <th>Sing 1P</th>
                                <th>Sing 2P</th>
                                <th>Sing 3P</th>
                                <th>Plural 1P</th>
                                <th>Plural 2P</th>
                                <th>Plural 3P</th>
                              </tr>
                            </thead>
                            <tbody>
                              {
                                WordStore.showData.conjugatedVerbStrings.map((item) => {
                                  return (
                                    <tr key={item.id}>
                                      <td>
                                        <Copy
                                          text={item.id}
                                        />
                                      </td>
                                      <td>
                                        {
                                          item.concept &&
                                            <Link
                                              to={{name: 'builder.concepts.edit', params: {conceptId: item.concept.id}}}
                                            >
                                              {item.concept.name}
                                            </Link>
                                        }
                                      </td>
                                      <td>
                                        <ConjugatedVerbStringGrouping>
                                          <InPlaceCheckbox
                                            value={item.hideSingular1p}
                                            field="hide_singular_1p"
                                            afterChange={this.afterChange}
                                            id={item.id}
                                            icon="ban"
                                            wrapperMargin="3px 3px 0 0"
                                            model="conjugated_verb_strings"
                                          />
                                          <InPlaceText
                                            defaultValue={item.singular1p}
                                            show={this.editableConjugations}
                                            field="singular_1p"
                                            id={item.id}
                                            color={item.hideSingular1p ? "#CCC" : "#000"}
                                            model="conjugated_verb_strings"
                                          />
                                        </ConjugatedVerbStringGrouping>
                                      </td>
                                      <td>
                                        <ConjugatedVerbStringGrouping>
                                          <InPlaceCheckbox
                                            value={item.hideSingular2p}
                                            field="hide_singular_2p"
                                            afterChange={this.afterChange}
                                            id={item.id}
                                            icon="ban"
                                            wrapperMargin="3px 3px 0 0"
                                            model="conjugated_verb_strings"
                                          />
                                          <InPlaceText
                                            defaultValue={item.singular2p}
                                            show={this.editableConjugations}
                                            field="singular_2p"
                                            id={item.id}
                                            color={item.hideSingular2p ? "#CCC" : "#000"}
                                            model="conjugated_verb_strings"
                                          />
                                        </ConjugatedVerbStringGrouping>
                                      </td>
                                      <td>
                                        <ConjugatedVerbStringGrouping>
                                          <InPlaceCheckbox
                                            value={item.hideSingular3p}
                                            field="hide_singular_3p"
                                            afterChange={this.afterChange}
                                            id={item.id}
                                            icon="ban"
                                            wrapperMargin="3px 3px 0 0"
                                            model="conjugated_verb_strings"
                                          />
                                          <InPlaceText
                                            defaultValue={item.singular3p}
                                            show={this.editableConjugations}
                                            field="singular_3p"
                                            id={item.id}
                                            color={item.hideSingular3p ? "#CCC" : "#000"}
                                            model="conjugated_verb_strings"
                                          />
                                        </ConjugatedVerbStringGrouping>
                                      </td>
                                      <td>
                                        <ConjugatedVerbStringGrouping>
                                          <InPlaceCheckbox
                                            value={item.hidePlural1p}
                                            field="hide_plural_1p"
                                            afterChange={this.afterChange}
                                            id={item.id}
                                            icon="ban"
                                            wrapperMargin="3px 3px 0 0"
                                            model="conjugated_verb_strings"
                                          />
                                          <InPlaceText
                                            defaultValue={item.plural1p}
                                            show={this.editableConjugations}
                                            field="plural_1p"
                                            id={item.id}
                                            color={item.hidePlural1p ? "#CCC" : "#000"}
                                            model="conjugated_verb_strings"
                                          />
                                        </ConjugatedVerbStringGrouping>
                                      </td>
                                      <td>
                                        <ConjugatedVerbStringGrouping>
                                          <InPlaceCheckbox
                                            value={item.hidePlural2p}
                                            field="hide_plural_2p"
                                            afterChange={this.afterChange}
                                            id={item.id}
                                            icon="ban"
                                            wrapperMargin="3px 3px 0 0"
                                            model="conjugated_verb_strings"
                                          />
                                          <InPlaceText
                                            defaultValue={item.plural2p}
                                            show={this.editableConjugations}
                                            field="plural_2p"
                                            id={item.id}
                                            color={item.hidePlural2p ? "#CCC" : "#000"}
                                            model="conjugated_verb_strings"
                                          />
                                        </ConjugatedVerbStringGrouping>
                                      </td>
                                      <td>
                                        <ConjugatedVerbStringGrouping>
                                          <InPlaceCheckbox
                                            value={item.hidePlural3p}
                                            field="hide_plural_3p"
                                            afterChange={this.afterChange}
                                            id={item.id}
                                            icon="ban"
                                            wrapperMargin="3px 3px 0 0"
                                            model="conjugated_verb_strings"
                                          />
                                          <InPlaceText
                                            defaultValue={item.plural3p}
                                            show={this.editableConjugations}
                                            field="plural_3p"
                                            id={item.id}
                                            color={item.hidePlural3p ? "#CCC" : "#000"}
                                            model="conjugated_verb_strings"
                                          />
                                        </ConjugatedVerbStringGrouping>
                                      </td>
                                    </tr>
                                  );
                                })
                              }
                            </tbody>
                          </table>
                      }
                      <div style={{display: 'flex'}}>
                        <button
                          style={{margin: "10px 10px 0 0"}}
                          className='gray-button'
                          onClick={() => WordStore.createEmptyVerbConjugations({ids: {wordId: this.props.params.wordId}}, this.afterChange)}
                        >
                          Create Empty Conjugations
                        </button>
                        <button
                          style={{margin: "10px 10px 0 0"}}
                          className='gray-button'
                          onClick={() => {
                            this.editableConjugations = !this.editableConjugations;
                            this.afterChange();
                          }}
                        >
                          <i className='fa fa-edit' />
                          Edit conjugations
                        </button>
                        <button
                          style={{margin: "10px 10px 0 0"}}
                          className='gray-button'
                          onClick={() => WordStore.createVerbConjugations({queryStrings: {site: "reverso"}, ids: {wordId: this.props.params.wordId}}, this.afterChange)}
                        >
                          Scrape Reverso
                        </button>
                        {
                          WordStore.showData.languageId === 'DE' &&
                            <button
                              style={{margin: "10px 10px 0 0"}}
                              className='gray-button'
                              onClick={() => WordStore.createVerbConjugations({queryStrings: {site: "woxikon"}, ids: {wordId: this.props.params.wordId}}, this.afterChange)}
                            >
                              Scrape Woxikon
                            </button>
                        }
                        {
                          WordStore.showData.languageId === 'DE' &&
                            <button
                              style={{margin: "10px 10px 0 0"}}
                              className='gray-button'
                              onClick={() => WordStore.createVerbConjugations({queryStrings: {site: "verbformen"}, ids: {wordId: this.props.params.wordId}}, this.afterChange)}
                            >
                              Scrape Verbformen
                            </button>
                        }
                      </div>
                    </fieldset>
                  </div>
                </div>
          }
          <div className="row">
            <div className="col-xs-12">
              <fieldset>
                <legend>Concepts</legend>
                <table className="table-wrapper">
                  <thead>
                    <tr>
                      <th width="20"></th>
                      <th>Concept</th>
                      {
                        WordStore.showData.root &&
                          <th>Show in Dictionary</th>
                      }
                    </tr>
                  </thead>
                  <tbody>
                    {
                      WordConceptStore.indexData.map((item) => {
                        return (
                          <tr key={item.id}>
                            <td>
                              <Link
                                to={{ name: 'builder.concepts.edit', params: { conceptId: item.id } }}
                                className="on-click"
                              >
                                <i className="fa fa-chevron-right fa-block" />
                              </Link>
                            </td>
                            <td>
                              <div className="text">
                                { item.name }
                              </div>
                            </td>
                            {
                              WordStore.showData.root &&
                              <td>
                                <CheckIfTrue
                                  value={item.showInDictionary}
                                  // label=""
                                />
                              </td>
                            }
                          </tr>
                        );
                      })
                    }
                  </tbody>
                </table>
                <div className='fieldset-info'>
                      <span
                        onClick={() => this.showConceptModal = true}
                        className='underline'
                        style={{marginRight: 20, fontSize: "14px", display: "flex", alignItems: "flex-end"}}
                      >
                        <i className="fa fa-lightbulb-o" style={{margin: "5px 5px 0 0"}} /> Edit concepts
                      </span>
                </div>
              </fieldset>
            </div>
          </div>
          <div className="row">
            <div className="col-xs-12">
              <fieldset>
                <legend>Sentences <InfoTooltip>Only sentences that are public and have a video appear for users.</InfoTooltip></legend>
                <table className="table-wrapper">
                  <thead>
                    <tr>
                      <th width="20"></th>
                      <th>Target</th>
                      <th width="50">Level</th>
                      <th width="50">Public</th>
                      <th width="50">Video</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      WordSentenceStore.indexData.map((item) => {
                        return (
                          <tr
                            key={item.id}
                          >
                            <td>
                              <Link
                                to={{ name: 'builder.sentences.edit', params: { sentenceId: item.id } }}
                                className="on-click"
                              >
                                <i className="fa fa-chevron-right fa-block" />
                              </Link>
                            </td>
                            <td>
                              <div className="text">
                                {item.targetText}
                              </div>
                            </td>
                            <td>
                              <div className="text">
                                {item.levelAbbreviation}
                              </div>
                            </td>
                            <td>
                              <CheckIfTrue
                                value={item.public}
                              />
                            </td>
                            <td>
                              <CheckIfTrue
                                value={item.hasTargetVideoClip}
                              />
                            </td>
                          </tr>
                        );
                      })
                    }
                  </tbody>
                </table>
                <Paginator
                  store={WordSentenceStore}
                  ids={{wordId: this.props.params.wordId}}
                />
              </fieldset>
            </div>
          </div>
          <div className="row">
            <div className="col-xs-12">
              <fieldset>
                <legend>Sentence Submissions</legend>
                <SentenceSubmissionIndex
                  wordId={this.props.params.wordId}
                />
              </fieldset>
            </div>
          </div>
          <div className="row">
            <div className="col-xs-12">
              <fieldset>
                <legend>
                  {`Worksheets (${WordStore.showData.worksheetsCount})`}
                </legend>
                {
                  WordStore.showData.worksheetsCount > 0 &&
                    <table className="table-wrapper">
                      <thead>
                      <tr>
                        <th width="20"></th>
                        <th>Name</th>
                        <th>Website</th>
                        <th width="100">Created At</th>
                      </tr>
                      </thead>
                      <tbody>
                      {
                        this.worksheets.map(worksheet => {
                          return (
                            <tr key={worksheet.id}>
                              <td>
                                <a href={worksheet.url} className='underline' target='_blank'>
                                  <i className="fa fa-chevron-right fa-block" />
                                </a>
                              </td>
                              <td>{worksheet.name}</td>
                              <td>{worksheet.site?.name}</td>
                              <td>{worksheet.publishedAt?.dashesWithYear}</td>
                            </tr>
                          );
                        })
                      }
                      </tbody>
                    </table>
                }
                {
                  WordStore.showData.worksheetsCount > 1 &&
                  <span className='underline' style={{fontWeight: 'normal', margin: '5px 5px 0 5px', fontSize: '12px'}} onClick={() => this.showWorksheets = !this.showWorksheets}>{`[${this.showWorksheets ? 'hide' : 'show more'}]`}</span>
                }
              </fieldset>
            </div>
          </div>
          {
            WordStore.showData.root &&
              <div className='row'>
                <div className='col-xs-12'>
                  <fieldset>
                    <legend>Frequency</legend>
                    <table>
                      <tbody>
                        <tr>
                          <td width="300px">Overall Frequency Ranking (max: {WordStore.showData.maxFrequencyRanking})</td>
                          <td width="300px">
                            <InPlaceText
                              model="words"
                              id={WordStore.showData.id}
                              field="frequency_ranking"
                              defaultValue={WordStore.showData.frequencyRanking}
                              afterChange={this.afterChange}
                            />
                          </td>
                        </tr>
                        <tr>
                          <td width="300px">Frequency Ranking 1 (higher number means higher ranking)</td>
                          <td width="300px">
                            <InPlaceText
                              model="words"
                              id={WordStore.showData.id}
                              field="frequency_ranking_1"
                              defaultValue={WordStore.showData.frequencyRanking1}
                              afterChange={this.afterChange}
                            />
                          </td>
                        </tr>
                        <tr>
                          <td width="300px">Frequency Ranking 2</td>
                          <td width="300px">{WordStore.showData.frequencyRanking2}</td>
                        </tr>
                        <tr>
                          <td width="300px">Frequency Ranking 3</td>
                          <td width="300px">{WordStore.showData.frequencyRanking3}</td>
                        </tr>
                        <tr>
                          <td width="300px">Frequency Score</td>
                          <td width="300px">{WordStore.showData.frequencyScore}</td>
                        </tr>
                        <tr>
                          <td width="300px">Frequency Score Adjustment (multiplier, between: 0.001 and 100)</td>
                          <td width="300px">
                            <InPlaceText
                              model="words"
                              id={WordStore.showData.id}
                              field="frequency_score_adjustment"
                              defaultValue={WordStore.showData.frequencyScoreAdjustment}
                              afterChange={this.afterChange}
                            />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </fieldset>
                </div>
              </div>
          }
          {
            this.mergeSpinner &&
              <Spinner />
          }
          {
             WordStore.showData.root && !this.mergeSpinner &&
              <button
                onClick={this.onMergeDuplicateWords}
                className='gray-button'
                style={{marginTop: '10px'}}
              >
                Merge With Duplicate Word (If Possible)
              </button>
          }
          {
            isPresent(this.mergeMessage) &&
            <Alert margin='10px 0 0 0'>{this.mergeMessage}</Alert>
          }
          <DeleteButton
            message="Delete Word? This will impact all sentences, sentence submissions, worksheets, etc."
            confirmationMessageFloatsRight
            onConfirm={this.onDelete}
            disabled={WordStore.showData.worksheetsCount > 0 || WordStore.showData.sentencesCount > 0}
          >
            <button
              className='gray-button'
              style={{marginTop: '10px', color: WordStore.showData.worksheetsCount > 0 || WordStore.showData.sentencesCount > 0 ? 'gray' : 'red'}}
              data-tip
              data-for='delete'
            >
              <i className='fa fa-times' style={{color: WordStore.showData.worksheetsCount > 0 || WordStore.showData.sentencesCount > 0 ? 'gray' : 'red'}} />
              Delete Word
              {
                (WordStore.showData.worksheetsCount > 0 || WordStore.showData.sentencesCount > 0) &&
                  <ReactTooltip
                    place="right"
                    type="dark"
                    effect="solid"
                    id='delete'
                    class="custom-tooltip"
                  >
                    <div style={{textTransform: 'none'}}>This word cannot be deleted because it is used in sentences or
                      worksheets.
                    </div>
                  </ReactTooltip>
              }
            </button>
          </DeleteButton>
        </div>
      );
    }
    return <span />;
  }

  render() {
    return this._content();
  }
}

export default WordEdit;
