import React from 'react';
import { observer } from 'mobx-react';
import { computed } from 'mobx';
import { OnboardingReferrerWrapper } from '@seedlang/hoc';
import ListSelect from 'components/list_select';
import Text from 'components/text';
import styled from '@emotion/styled';
import { flexCenterColumn } from '@seedlang/style_mixins';
import { Theme } from '@seedlang/constants';
import { isPresent } from '@seedlang/utils';
import Button from 'components/button/button';
import OnboardingContent from 'components/onboarding/onboarding_content';
import OnboardingWrapper from 'components/onboarding/onboarding_wrapper';
import { isBlank } from '@seedlang/utils';

const Continue = styled.div`
  margin-top: 20px;
`;

@observer
class OnboardingReferrer extends React.Component {

  @computed get otherId() {
    return isPresent(this.props.referrerOptions) && this.props.referrerOptions.find(item => item.text === 'Other').id;
  }

  render() {
    return (
      <OnboardingWrapper>
        <OnboardingContent>
          <Text
            center
            heading="3"
            margin="0 0 20px 0"
            color="#333"
          >
            How did you hear about us?
          </Text>
          <ListSelect
            value={this.props.referrerOptionId}
            options={this.props.referrerOptions}
            onSelect={this.props.onSetReferrer}
            fieldName="text"
          />
        </OnboardingContent>
        <Continue>
          <Button
            disabled={isBlank(this.props.referrerOptionId)}
            onClick={this.props.onSubmitReferrer}
            width='440px'
            height='60px'
          >
            Continue
          </Button>
        </Continue>
      </OnboardingWrapper>
    );
  }
}

export default OnboardingReferrerWrapper(OnboardingReferrer);
