import React from 'react';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import styled from '@emotion/styled';
import { AppUI } from '@seedlang/state';
import Text from 'components/text';
import autobind from 'autobind-decorator';
import { pixify, isPresent } from '@seedlang/utils';

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100px;
  background: #FFF;
  margin: 20px 0 0 0;
`;

const ImageWrapper = styled.div`
  img {
    height: 80px;
  }
`;

const SpeechBubble = styled.div`
  position: relative;
  background-color: #fff;
  border-radius: 10px;
  padding: 15px;
  width: 260px;
  border: 2px solid #CCC;
  font-size: 15px;
  font-weight: bold;
  color: #333;
  margin-left: 20px;
  min-height: ${props => props.minHeight};
`;

const ArrowWrapper = styled.div`
  position: absolute;
  left: -17px;
  top: 15px;
`;

const PAUSE_BETWEEN_SENTENCES = 1500;

@observer
class OnboardingIllustration extends React.Component {
  @observable displayedText = '';
  @observable characterIndex = 0;
  @observable arrayIndex = 0;
  @observable textToDisplay = '';
  @observable displayMultipleSentences = false;

  constructor(props) {
    super(props);
    this.initializeText();
    this.updateText();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.text !== this.props.text) {
      this.arrayIndex = 0;
      this.displayMultipleSentences = false;
      this.initializeText();
      this.updateText();
    }
  }

  @autobind resetTextForNextSentence() {
    this.initializeText();
    this.updateText();
  }

  @autobind updateText() {
    if (isPresent(this.textToDisplay)) {
      if (this.characterIndex < this.textToDisplay.length) {
        this.displayedText += this.textToDisplay[this.characterIndex];
        this.characterIndex ++;
        setTimeout(this.updateText, 20);
      } else if (this.displayMultipleSentences) {
        this.arrayIndex ++;
        if (isPresent(this.props.text[AppUI.targetLanguageId][this.arrayIndex])) {
          setTimeout(this.resetTextForNextSentence, PAUSE_BETWEEN_SENTENCES);
        }
      }
    }
  }

  @autobind initializeText() {
    this.displayedText = '';
    this.characterIndex = 0;
    if (typeof this.props.text === 'object') {
      if (typeof this.props.text[AppUI.targetLanguageId] === 'object') {
        this.displayMultipleSentences = true;
        this.textToDisplay = this.props.text[AppUI.targetLanguageId][this.arrayIndex];
      } else {
        this.textToDisplay = this.props.text[AppUI.targetLanguageId];
      }
    } else {
      this.textToDisplay = this.props.text;
    }
  }

  render () {
    return (
      <Wrapper>
        {
          this.props.images &&
            <ImageWrapper>
              <img src={`/images/${this.props.images[AppUI.targetLanguageId]}`} />
            </ImageWrapper>
        }
        <SpeechBubble
          minHeight={pixify(this.props.speechBubbleHeight)}
        >
          <ArrowWrapper>
            <img src="/images/speech_bubble_arrow.png" />
          </ArrowWrapper>
          {this.displayedText}
        </SpeechBubble>
      </Wrapper>
    );
  }
}

export default OnboardingIllustration;
