import React from 'react';
import { observer } from 'mobx-react';
import { OnboardingNativeLanguageWrapper } from '@seedlang/hoc';
import InPlaceLanguageSelect from 'components/form/in_place_language_select';
import Text from 'components/text';
import styled from '@emotion/styled';
import { flexCenterColumn } from '@seedlang/style_mixins';
import { AppUI } from '@seedlang/state';
import Button from 'components/button/button';
import { Theme, Languages } from '@seedlang/constants';
import ListSelect from 'components/list_select';
import OnboardingContent from 'components/onboarding/onboarding_content';
import OnboardingWrapper from 'components/onboarding/onboarding_wrapper';

const Continue = styled.div`
  margin-top: 20px;
`;

@observer
class OnboardingNativeLanguage extends React.Component {

  render() {
    return (
      <OnboardingWrapper>
        <OnboardingContent>
          <ListSelect
            value={this.props.languageId}
            options={this.props.languages.filter(item => item.usersCount > 100)}
            onSelect={this.props.onSetNativeLanguageId}
            fieldName="name"
          />
        </OnboardingContent>
        <Continue>
          <Button
            onClick={this.props.onSubmitNativeLanguageId}
            width='440px'
            height='60px'
          >
            Continue
          </Button>
        </Continue>
      </OnboardingWrapper>
    );
  }
}

export default OnboardingNativeLanguageWrapper(OnboardingNativeLanguage);
